import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Popup from '../components/Popup';
import '../styles/policy.scss';

const TermsAndConditions = () => {
  const [modalVisible, setModalVisible] = useState('');

  const togglePopup = useCallback(
    (value) => setModalVisible((state) => (state === value ? '' : value)),
    [],
  );
  const closePopup = useCallback(() => setModalVisible(0), []);

  useEffect(() => {
    modalVisible
      ? document.body.classList.add('overflow')
      : document.body.classList.remove('overflow');
  }, [modalVisible]);

  return (
    <Layout togglePopup={togglePopup}>
      <SEO title="Terms & Conditions" />
      <Popup modalVisible={modalVisible === 'spaces'} closePopup={closePopup}>
        <div className="space-video">
          <video
            autoPlay
            loop
            muted
            playsInline
            src="/video/spaces.mp4"
            style={{ width: '500px' }}></video>
        </div>
        <h4>Spaces by Brutask</h4>
        <p>
          Spaces by Brutask is created to replicate your virtual office.
          Navigate across floors, join a room &amp; discuss with your team via
          audio &amp; video. You can also share your screen with all othe
          participants inside the room. What's better - you can share the room
          link with external members outside your team, and they can join
          without any login!
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
        <a
          className="button button-unstyled"
          href="https://www.loom.com/share/c0ad1d9ddb6f48fe9e1040c7f6550ca3"
          target="_blank"
          rel="noopener noreferrer">
          Watch Demo
        </a>
      </Popup>
      <Popup
        modalVisible={modalVisible === 'pricing'}
        closePopup={closePopup}
        className="popup-center">
        <h3>
          Pay as you <span className="yellow">grow</span>
        </h3>
        <p>
          You will be charges <span className="semi-bold">$15/month</span> for
          the plan that will include: 10 members, 3 floors and unlimited guest
          access. <span className="semi-bold">$2</span> would be charged for
          every additional member
        </p>
        <a className="button" href="http://app.brutask.com/">
          Get started
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path
              d="M6.60019 0.199951L5.4722 1.32795L9.93619 5.79995H0.200195V7.39995H9.93619L5.4722 11.872L6.60019 13L13.0002 6.59995L6.60019 0.199951Z"
              fill="white"
            />
          </svg>
        </a>
      </Popup>
      <section className="policy">
        <div className="container">
          <div className="inner-common-hero">
            <h1 className="privacy-head">
              Terms and Conditions for Usage of Brutask app
            </h1>
          </div>
        </div>
        <section>
          <div className="container">
            <div className="privacy-content">
              <h2>Welcome to Brutask!</h2>
              <p>
                Brutask service (“Service”) is provided by Brucira Online
                Solutions Pvt. Ltd. ("Company"). By using the Service, you are
                agreeing to be bound by the following terms and conditions
                ("Terms"). Company reserves the right to update and change these
                Terms without notice. Violation of any of the terms below may
                result in the termination of your account.
              </p>

              <h2>CHANGES</h2>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. By continuing to access or use
                our Service, you agree to be bound by the revised terms. If you
                do not agree to the new terms, please stop using the Service.
              </p>

              <h2>PRIVACY AND COMMUNICATIONS</h2>
              <p>
                You acknowledge and agree that the Service may occasionally send
                you communications regarding your account or the Service via
                email. See the{' '}
                <Link to="/privacy-policy" className="green semi-bold">
                  Privacy Policy
                </Link>
                , which is incorporated into this Agreement by reference.
              </p>

              <h2>MINIMUM AGE</h2>
              <p>You must be at least 18 years old to use the Service.</p>

              <h2>ACCOUNTS, PASSWORDS, AND SECURITY</h2>
              <p>
                You must be a registered user of Brutask (
                <a href="https://app.brutask.com" className="green semi-bold">
                  https://app.brutask.com
                </a>
                ) to access the Service. You will be solely responsible and
                liable for any activity that occurs under your user name.
              </p>

              <h2>NO WARRANTIES OR REPRESENTATIONS</h2>
              <p>
                You understand and agree that the Service is provided "as is'',
                and the Company, its affiliates, suppliers, employees and
                Resellers expressly disclaim all warranties of any kind, express
                or implied, including without limitation any warranty of
                merchantability, fitness for a particular purpose,
                non-infringement or bailment of your data on Brutask's servers.
                The Company, its affiliates, suppliers, employees and Resellers
                make no warranty or representation, regarding the results that
                may be obtained from the use of the Service, the security of the
                Service, or that the Service will meet any user's requirements.
                Use of the Service is at the user's (your) sole risk. You will
                be solely responsible for any damage to You resulting from the
                use of the Service. The entire risk arising out of use, security
                or performance of the Service remains with You. Without limiting
                the foregoing, the Service is not designed or licensed for use
                in hazardous environments requiring fail-safe controls,
                including without limitation operation of nuclear facilities,
                aircraft navigation/communication systems, air traffic control,
                and life support or weapons systems.
              </p>

              <h2>LIMITATION OF LIABILITY</h2>
              <p>
                In no event shall Company be liable for any indirect, special,
                incidental, consequential or punitive damages (including but not
                limited to loss of use, loss of profits, or loss of data)
                whether in an action in contract, tort (including but not
                limited to negligence), equity or otherwise, arising out of or
                in any way connected with the use of or inability to use this
                site or the materials therein or resulting from unauthorised
                access to or alteration of data.
              </p>

              <h2>TERMINATION OF SERVICE</h2>
              <p>
                We reserve the right to terminate your account at any time. You
                also have the option of cancelling your account at any time
                without penalty. In the event of account cancellation, you will
                lose all data related to your Brutask account.
              </p>

              <h2>CONDITIONS</h2>
              <p>
                We may modify or terminate our services at any time, for any
                reason, and without notice. We reserve the right to modify these
                Terms of Service at any time without notice. Please review these
                Terms of Service on occasion as they may change in the future.
                We may, but have no obligation to, remove accounts and content
                containing what we determine as unlawful, offensive,
                threatening, defamatory, obscene or otherwise objectionable
                material. We will remove content that violates any party's
                intellectual property or these Terms of Service. An account
                terminated by Service will not be backed up for any reason and
                will be immediately deleted from our servers.
              </p>

              <h2>INTELLECTUAL PROPERTY</h2>
              <p>
                The Company claims no intellectual property rights over the
                material you provide to the Service. You acknowledge that the
                Company owns all the rights, titles and interests in and to the
                Service, including without limitation all intellectual property
                rights and such rights are protected by Indian and international
                intellectual property laws. You agree that you will not copy,
                reproduce, alter, modify, or create derivative works from the
                Service.
              </p>
              <p>
                Users with questions about these Terms or the Privacy Policy may
                contact Brucira at{' '}
                <a
                  href="mailto:support@brutask.com"
                  className="green semi-bold">
                  support@brutask.com
                </a>
                , or at B504, PNG Building, Mumbai, Maharashtra 400076, India.
              </p>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default TermsAndConditions;
